import React from 'react'
import { formatLocaleDate } from '../lib/dates'

import type { Report } from '../dataTypes'
import { DownloadIcon } from './icons'
import ColoredStatus from './ColoredStatus'
import { hasExceededThreshold } from './utils'
import { downloadReport } from '../services'

type Props = {
  report: ?Report,
}

export const ReportDetails = (props: Props) => {
  if (!props.report) {
    return (
      <div>
        <h2>Report doesn't exist</h2>
      </div>
    )
  }

  const {
    name,
    clearingHouse,
    reportType,
    recipients,
    frequency,
    periodDefinition,
    lastRun,
    lastRunReportUrl,
    lastStatus,
    nextRun,
  } = props.report

  const recipientsText = recipients && recipients.length ? recipients.join(', ') : 'None'

  return (
    <div className="card">
      <h2 className="text-lg p-md">{name}</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>
              Type: <b>{reportType}</b>
            </td>
            <td>
              Clearinghouse: <b>{clearingHouse}</b>
            </td>
          </tr>
          <tr>
            <td>
              Period definition: <b>{periodDefinition}</b>
            </td>
            <td>
              Frequency: <b>{frequency}</b>
            </td>
          </tr>
          <tr>
            <td className="flex">
              Last event: <b>{lastRun && formatLocaleDate(new Date(lastRun))}</b>
              {(lastStatus === 'DELIVERED' || lastStatus === 'DELIVERY_SKIPPED') && (
                <div className="btn btn-icon" onClick={() => downloadReport(lastRunReportUrl)}>
                  <DownloadIcon color="var(--colors-green)" size="20" />
                </div>
              )}
            </td>
            <td>
              Next run: <b>{formatLocaleDate(new Date(nextRun))}</b>
            </td>
          </tr>
          <tr>
            <td>
              Last status:{' '}
              <b>
                <ColoredStatus status={lastStatus} exceededTimeout={hasExceededThreshold(lastRun)} />
              </b>
            </td>
            <td>
              Recipients: <b>{recipientsText}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ReportDetails
