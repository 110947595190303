import React from 'react'
import { formatLocaleDate } from '../lib/dates'
import { DownloadIcon, UpdateIcon } from './icons'
import ColoredStatus from './ColoredStatus'

import type { ReportHistory } from '../dataTypes'
import { hasExceededThreshold } from './utils'
import { downloadReport } from '../services'

type Props = {
  definitionId: number,
  history: ReportHistory,
  retryReport: Function,
  retryFailedReportDeliveries: Function,
}

type State = {
  retrying: boolean,
}

export class ReportHistoryRow extends React.Component<Props, State> {
  state = { retrying: false }

  render() {
    const { from, to, lastEvent, status, reportUrl } = this.props.history
    const reportId = this.props.history.reportId
    const definitionId = this.props.definitionId
    const exceededTimeout = hasExceededThreshold(lastEvent)

    const inProgress =
      this.state.retrying || (['GENERATING', 'GENERATED', 'DELIVERING'].includes(status) && !exceededTimeout)
    const isGenerated = !['GENERATING', 'GENERATION_FAILED'].includes(status)
    const isGenerationFailed =
      !this.state.retrying &&
      (['GENERATION_FAILED'].includes(status) || (['GENERATING', 'GENERATED'].includes(status) && exceededTimeout))
    const isDeliveryFailed =
      !this.state.retrying &&
      (['DELIVERY_FAILED'].includes(status) || (['DELIVERING'].includes(status) && exceededTimeout))

    return (
      <tr>
        <td>
          <a className="link" href={`${definitionId}/${reportId}`}>
            <h2>{reportId}</h2>
          </a>
        </td>
        <td>{formatLocaleDate(new Date(from))}</td>
        <td>{formatLocaleDate(new Date(to))}</td>
        <td>{lastEvent && formatLocaleDate(new Date(lastEvent))}</td>
        <td>
          {inProgress && <div className="loadingSpinner" />}
          {isGenerated && (
            <div className="btn btn-icon" onClick={() => downloadReport(reportUrl)}>
              <DownloadIcon className="inline-block" color="var(--colors-green)" size="25" />
            </div>
          )}
          {isGenerationFailed && (
            <button
              className="btn btn-icon inline-block"
              title="Retry report generation"
              onClick={() => {
                this.props.retryReport(reportId, definitionId)
                this.setState({ retrying: true })
              }}
            >
              <UpdateIcon className="inline-block" color="var(--colors-blue-light)" size="25" />
            </button>
          )}
          {isDeliveryFailed && (
            <button
              className="btn btn-icon inline-block"
              title="Retry failed deliveries"
              onClick={() => {
                this.props.retryFailedReportDeliveries(reportId)
                this.setState({ retrying: true })
              }}
            >
              <UpdateIcon className="inline-block" color="var(--colors-blue-light)" size="25" />
            </button>
          )}
        </td>
        <td>
          <ColoredStatus status={status} exceededTimeout={exceededTimeout} />
        </td>
      </tr>
    )
  }
}

export default ReportHistoryRow
