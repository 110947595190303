import { authenticatedFetch } from '../lib/authenticatedFetch'
import { API_HOST, API_VERSION } from '../lib/environment'

import type { Report, ReportDetails, ReportHistory } from '../dataTypes'
import { authentication } from '../lib/authentication'

let versionOverride
try {
  versionOverride = new URLSearchParams(window.location.search).get('version')
} catch (e) {
  // ignore
}

const version = versionOverride === '1' || versionOverride === '2' ? versionOverride + '.0' : API_VERSION
const COMPLETE_API_HOST = `${API_HOST}/v${version}`

type ReportRetryResponse = {
  data: null,
}

export function fetchReports(): Promise<Array<Report>> {
  return authenticatedFetch(`${COMPLETE_API_HOST}/overview`).then((resp) => resp.data.report_definitions)
}

export function fetchReportHistory(definition_id: number): Promise<Array<ReportHistory>> {
  return authenticatedFetch(`${COMPLETE_API_HOST}/definition/${definition_id}`).then((resp) => resp.data)
}

export function fetchReportDeliveryDetails(reportId: number): Promise<ReportDetails> {
  return authenticatedFetch(`${COMPLETE_API_HOST}/report/${reportId}`).then((resp) => resp.data)
}

/**
 * If report is in failed delivery, this will retry delivery, of not the whole report will be retried
 */
export function retryReport(id: number): Promise<ReportRetryResponse> {
  const options = {
    method: 'POST',
  }

  return authenticatedFetch(`${COMPLETE_API_HOST}/report/${id}/retry`, options)
}

/**
 * Will only retry failed deliveries, responds CONFLICT 409 if report is in another state than DELIVERY_FAILED
 */
export function retryFailedReportDeliveries(reportId: number): Promise<ReportRetryResponse> {
  const options = {
    method: 'POST',
  }
  return authenticatedFetch(`${COMPLETE_API_HOST}/report/${reportId}/retryFailed`, options)
}

/**
 * Will only retry failed delivery of a single part, responds CONFLICT 409 if report or part is in another state than DELIVERY_FAILED
 */
export function retryFailedPartDelivery(reportId: number, partId: string): Promise<ReportRetryResponse> {
  const options = {
    method: 'POST',
  }

  return authenticatedFetch(`${COMPLETE_API_HOST}/report/${reportId}/part/${partId}/retry`, options)
}

export function skipFailedPartDelivery(reportId: number, partId: string): Promise<ReportRetryResponse> {
  const options = {
    method: 'POST',
  }

  return authenticatedFetch(`${COMPLETE_API_HOST}/report/${reportId}/part/${partId}/skip`, options)
}

export function downloadReport(url): Promise<any> {
  return authenticatedFetch(url, authentication).then((response) => {
    window.location.href = response.data
  })
}
